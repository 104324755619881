<template>
  <authenticated-page>
    <full-page-message v-if="projectId && !isFetched">
      <loading-spinner />
    </full-page-message>
    <go-back-message
      v-else-if="!projectId || !project"
      :title="$t('projects.projectNotFound')"
      :description="$t('projects.projectNotFoundDescription')"
      @click="goToListOfProjects"
    />
    <go-back-message
      v-else-if="!hasAccess"
      :title="$t('projects.projectNoAccess')"
      :description="
        $t('projects.projectNoAccessDescription', {
          projectName: project.name,
          projectSlug: project.slug,
        })
      "
      @click="goToListOfProjects"
    />
    <slot v-else />
  </authenticated-page>
</template>

<script>
import { defineComponent } from 'vue'

import AuthenticatedPage from '@/components/templates/AuthenticatedPage'
import FullPageMessage from '@/components/templates/FullPageMessage'
import GoBackMessage from '@/components/templates/GoBackMessage'
import LoadingSpinner from '@/components/atoms/LoadingSpinner'

import { hasAccessToProject } from '@/utils/project'

export default defineComponent({
  name: 'ProjectPage',
  components: {
    AuthenticatedPage,
    FullPageMessage,
    GoBackMessage,
    LoadingSpinner,
  },
  inject: ['project', 'projectId'],
  computed: {
    hasAccess() {
      const portal = this.$store.getters.getPortalById(this.project?.portalId)
      return hasAccessToProject(this.project, portal)
    },
    isFetched() {
      return this.$store.getters.isProjectFetched(this.projectId)
    },
  },
  methods: {
    goToListOfProjects() {
      if (this.project) {
        const portal = this.$store.getters.getPortalById(this.project?.portalId)
        this.$router.push({
          name: 'projectListRetrocompatibility',
          params: { portalSlug: portal.slug },
        })
      } else {
        this.$router.push('/projects')
      }
    },
  },
})
</script>
