<template>
  <project-page>
    <div class="h-screen font-montserrat flex flex-col items-stretch">
      <page-header small @back="back">
        <personal-note-header />
      </page-header>
      <div class="flex-1 flex p-2">
        <personal-note-editor />
      </div>
    </div>
  </project-page>
</template>

<script>
import { defineComponent } from 'vue'

import ProjectPage from '@/components/templates/ProjectPage.vue'
import PersonalNoteEditor from '@/components/organisms/PersonalNoteEditor.vue'
import PersonalNoteHeader from '@/components/organisms/PersonalNoteHeader.vue'
import PageHeader from '@/components/molecules/PageHeader.vue'

import { back } from '@/plugins/router'

export default defineComponent({
  name: 'PreferencesPage',
  components: {
    ProjectPage,
    PersonalNoteEditor,
    PersonalNoteHeader,
    PageHeader,
  },
  methods: {
    back() {
      back('project', this.$route.params)
    },
  },
})
</script>
