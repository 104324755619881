<template>
  <div class="flex-1 flex flex-row items-stretch content-center">
    <textarea
      class="
        flex-1
        bg-secondary-100
        border border-secondary-500
        rounded
        p-1
        resize-none
        text-base
      "
      :placeholder="$t('personalNote.write')"
      v-model="content"
    ></textarea>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    content: {
      get() {
        return this.$store.getters.personalNote(this.$route?.params?.projectId)
          ?.content
      },
      set(value) {
        this.$store.commit('setPersonalNoteContent', value)
      },
    },
  },
})
</script>

<style scoped>
button {
  margin: 10px;
}
</style>
