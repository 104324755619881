<template>
  <button
    :type="type"
    class="flex items-center justify-center py-2 px-4 rounded transition-colors"
    :class="classes"
    :disabled="disabled"
    @click="onClick"
  >
    <icon :icon="icon" v-if="icon" class="mr-1" size="xs" /> {{ label }}
  </button>
</template>

<script>
import { reactive, computed, defineComponent } from 'vue'

import Icon from '@/components/atoms/Icon'

export default defineComponent({
  name: 'Button',

  props: {
    label: {
      type: String,
      default: '',
    },
    primary: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: () => 'button',
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    class: {
      type: String,
      default: () => '',
    },
    size: {
      type: String,
      validator: function (value) {
        return ['small', 'medium', 'large'].indexOf(value) !== -1
      },
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    icon: {
      type: String,
      default: () => '',
    },
  },
  components: {
    Icon,
  },

  emits: ['click'],

  setup(props, { emit }) {
    props = reactive(props)
    return {
      classes: computed(() => ({
        'text-base font-semibold': props.size === 'large',
        'text-sm h-10 font-semibold': props.size === 'medium',
        'bg-primary disabled:opacity-50': props.primary,
        'hover:bg-primary-hover': props.primary,
        'focus:bg-primary-active': props.primary,
        'text-text-500': props.primary,
        'bg-secondary-400': props.secondary,
        'hover:bg-secondary-500': props.secondary,
        'focus:bg-secondary-500': props.secondary,
        'text-secondary-700': props.secondary,
        [props.class]: true,
      })),
      onClick(event) {
        emit('click', event)
      },
    }
  },
})
</script>
