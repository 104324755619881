<template>
  <div
    class="text-secondary-700 flex items-center"
    :class="{ 'p-5': !small, 'px-4 pt-4 pb-2': small }"
  >
    <icon-button :icon="arrowBackOutline" @click="back" size="medium" />
    <span class="text-xl font-semibold ml-2">
      <slot />
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { arrowBackOutline } from 'ionicons/icons'

import IconButton from '@/components/atoms/IconButton'

export default defineComponent({
  components: {
    IconButton,
  },
  emits: ['back'],
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      arrowBackOutline,
    }
  },
  methods: {
    back() {
      this.$emit('back')
    },
  },
})
</script>
