<template>
  <full-page-message>
    <icon class="text-5xl" :src="warningOutline" />
    <div class="pt-2 text-xl font-bold uppercase">
      {{ title }}
    </div>
    <div
      class="
        text-base
        pt-1
        pb-6
        font-base
        text-secondary-400
        max-w-screen-sm
        text-center
      "
    >
      {{ description }}
    </div>

    <Button
      secondary
      :label="$t('projects.goToListOfProjects')"
      @click="$emit('click')"
    />
  </full-page-message>
</template>

<script>
import { defineComponent } from 'vue'
import { warningOutline } from 'ionicons/icons'

import FullPageMessage from '@/components/templates/FullPageMessage'
import Button from '@/components/atoms/Button'
import Icon from '@/components/atoms/Icon'

export default defineComponent({
  components: {
    FullPageMessage,
    Button,
    Icon,
  },
  setup() {
    return {
      warningOutline,
    }
  },
  emits: ['click'],
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
})
</script>
