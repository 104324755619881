<template>
  <div class="">
    {{ $t('title.PersonalNote') }}
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({})
</script>
